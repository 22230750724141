<template>
    <div style="height: 100%" id="help">
        <!-- <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-a-14-14"></i>
                <span class="page-name">帮助中心</span>
            </div>
            <div slot="top-header-right" class="top-header-right">
            </div>
        </page-top-header> -->
        <div id="help-content-main">
            <!-- <iframe width="100%" frameborder="no" border="0" height="100%" src="http://askbot.baklib-free.com/"></iframe> -->
            <iframe
                width="100%"
                frameborder="no"
                border="0"
                height="100%"
                :src="helpSrc"
            ></iframe>
        </div>
    </div>
</template>

<script>
import pageTopHeader from "../../components/page-top-header.vue";
export default {
    name: "help",
    components: {
        pageTopHeader,
    },
    data() {
        return {
            activePageInfo: {
                headerName: "使用帮助",
            },
            helpSrc: "https://showdoc.askbot.cn/web/#/readonly/5?page_id=24",
        };
    },
    mounted() {
        console.log(this.$route.params,'params');
        let helpSrc = ''
        helpSrc = sessionStorage.getItem('helpSrc') || '';
        if(!helpSrc || helpSrc === ''){
            this.helpSrc = this.$route.params.helpSrc || 'https://showdoc.askbot.cn/web/#/readonly/5?page_id=24';
        } else {
            this.helpSrc = helpSrc;
        }
        
    },
    methods: {
        //点击li增加样式切换锚点
        changeClass(index, anchor) {
            this.activeClass = index;
            this.anchor = anchor;
            document.querySelector(anchor).scrollIntoView(true);
        },
    },
};
</script>

<style lang="less" scoped>
@import "./../../assets/less/help/help.less";
</style>
