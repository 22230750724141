<!--渠道接入页面的设置欢迎语--->
<template>
    <div class="set-welcome-message" >
        <!-- 配置欢迎语弹框 -->
        <el-drawer
            id="ans-content"
            title
            :visible.sync="welcomeMessageDrawer"
            :wrapperClosable="false"
            :direction="'rtl'"
            :size="1000">
            <!-- 组件选列表 -->
            <div class="grid-content components">
                <ComponentsList
                    :nodeId="nodeId"
                    :botVersion="botVersion"
                    ref="componentsListRfe"
                    @addComponents="addComponents"
                ></ComponentsList>
            </div>
            <!-- 流程配置 -->
                <div class="grid-content interaction-config">
                <div class="interaction-config-header">
                    <ConfigHeader
                        v-if="welcomeMessageDrawer"
                        ref="configheader"
                        :isPart="isPart"
                        :nodeId="nodeId"
                        :intentId="intentId"
                        :isStartOrEnd="isStartOrEnd"
                        :isOtherSkill="isOtherSkill"
                        :activeNodeName="activeNodeName"
                        :cancelSaveVisible="cancelSaveVisible"
                        :nodeTree="nodeTree"
                        @closePartDrewer="closePartDrewer"
                        @cancelSaveConfirm="cancelSaveConfirm"
                        @checkActionListChanged="checkActionListChanged"
                        @saveChatFlow="saveChatFlow"
                        @closeIntentNodeList="closeIntentNodeList"
                        @changeActiveNodeName="changeActiveNodeName"
                        @changeCancelSaveVisible="changeCancelSaveVisible">
                    </ConfigHeader>
                </div>
                </div>
            
        </el-drawer>
        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip :tipIndex="tipIndex" />
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false">我知道了</el-button>
                <el-button
                    type="primary"
                    size="small"
                    v-clipboard:copy="'4001-789-800'"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
                    拨打 4001-789-800 升级
                </el-button>
            </div>
        </popup>
    </div>
    
</template>

<script>
import popup from "../../../components/popupNew.vue";
import VersionTip from "../../version/VersionTip.vue";
// 选择组件列表
import ComponentsList from "../../ChatFlow/component/cell/ComponentsList.vue";
// 组件配置头部
import ConfigHeader from "../../ChatFlow/component/cell/ConfigHeader.vue";

// answerInit
import { answerInit } from "../../ChatFlow/utils/answerInit";
export default {
    components:{
        popup,
        ComponentsList,
        VersionTip,
        ConfigHeader
    },
    data(){
        return {
            // welcomeMessageDrawer:false, // 欢迎语drawer
            nodeId:'start', // 开始节点
            botVersion:"",
            versionTipDialog:false,
            tipIndex: 2,
            actionList:[],
            isStartOrEnd: false, // 当前操作是否为开始或者结束节点
            custonerOptions: [], // 转人工
            customerServiceOfflineIntentId: "", // 没有客服在线默认跳转到意图
            isPart:0,
            intentId:0, // 开始节点为0
            isOtherSkill: false, // 是否是特殊规则的知识库
            activeNodeName:"开始",
            cancelSaveVisible: false,// 取消保存确认
            nodeTree:[],
            actionListCopy: [],
        }
    },
   
    props:{
        welcomeMessageDrawer:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        welcomeMessageDrawer(n){
            if(n){
                this.initWelcomeMessageFn();
            }
        }
    },
    methods:{
      
       // 对话流程中的mounted方法
       initWelcomeMessageFn(){
           // 获取用户分组list
            this.FetchGet(this.requestUrl.accountGroup.getAccountGroupList).then((res) => {
                if (res.code === "0") {
                    this.custonerOptions = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
            this.FetchGet(this.requestUrl.bot.getBotInfo + "/" + this.$route.query.id).then((res) => {
                if (res.code === "0") {
                    this.customerServiceOfflineIntentId = String(
                        res.data.setting.customerServiceOfflineIntentId
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
       },
       // 点击组件添加到右侧
        addComponents(type, callback) {
            // action_webhook -- WebHook
            if (type == "action_webhook" && localStorage.getItem("_bot_version") == 2 ) {
                this.tipIndex = 2;
                this.versionTipDialog = true;
                return false;
            }
            
            if (this.actionList == null || this.actionList == undefined) {
                this.actionList = [
                    answerInit(
                        type,
                        this.isStartOrEnd,
                        this.custonerOptions,
                        this.customerServiceOfflineIntentId
                    ),
                ];
            } else {
                this.actionList.push(
                    answerInit(
                        type,
                        this.isStartOrEnd,
                        this.custonerOptions,
                        this.customerServiceOfflineIntentId
                    )
                );
            }
            
            // 滚动到底部
            this.$nextTick(() => {
                if(callback) {
                    // 延迟处理防止 回调执行时节点还未生成
                    setTimeout(() => {
                        callback()   
                    }, 200);
                }
                if(type == "answer_text"){
                    let name = `answer_focus_text_${this.actionList.length - 1}`;
                    this.$refs[name][0].focusFirst();
                } 
                setTimeout(() => {
                    this.$refs.grContent.scrollTo(0, 100000000);
                    console.log(4035);
                }, 200)
            });
        },
        // 关闭drawer
        closePartDrewer() {
            this.$refs.componentsListRfe.activeTab = 0;
            this.$emit('update:welcomeMessageDrawer',false)
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay("");
                })
            })
        },
        // 取消保存
        cancelSaveConfirm() {
            this.$refs.componentsListRfe.activeTab = 0;
            this.cancelSaveVisible = false;
            this.$emit('update:welcomeMessageDrawer',false)
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay("");
                })
            })
        },
        // 检测是否有更改
        checkActionListChanged() {
            let newVal = JSON.stringify(this.actionList);
            let oldVal = JSON.stringify(this.actionListCopy);
            newVal = this.checkFormatRich(newVal)
                ? newVal
                : this.formatRichTextNew(newVal);
            oldVal = this.checkFormatRich(oldVal)
                ? oldVal
                : this.formatRichTextNew(oldVal);
            if (
                newVal.split(" ").join("").length ==
                oldVal.split(" ").join("").length
            ) {
                this.cancelSaveConfirm();
            } else {
                if (
                    !(
                        (this.nodeId == "start" &&
                            !this.RP_Visible("OPEN_PROCESS_START")) ||
                        (this.nodeId == "end" &&
                            !this.RP_Visible("OPEN_PROCESS_END")) ||
                        (!this.isStartOrEnd &&
                            !this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_CUSTOMIZE")) ||
                        (this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_OTHER"))
                    )
                ) {
                    this.cancelMoveNodeSaveVisible = false;
                    this.cancelSaveVisible = true;
                    this.$refs.configheader.cancelSaveVisible = true;
                } else {
                    this.$refs.componentsListRfe.activeTab = 0;
                    this.drawer = false;
                    this.$nextTick(() => {
                        this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                            item.stopAudioPlay("");
                        })
                    })
                }
            }
        },
         // Copy成功
        onCopy(e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            this.$message.error("抱歉，复制失败！");
        },
    }

}
</script>

<style>

</style>