<template>
    <div id="dingding-group-list">
        <div class="dingding-group-header">
            <span class="dingding-group-header-des">
                <span class="group-list-title">{{$t('botLink.dingGroupListDrawer.title')}}</span>
                <span class="group-list-des"
                    >{{$t('botLink.dingGroupListDrawer.tip')}}</span
                >
            </span>

            <el-button
                @click="asynToAllGroup"
                size="mini"
                type="primary"
                plain
                round
                >{{$t('botLink.dingGroupListDrawer.sync')}}</el-button
            >
        </div>
        <div class="dingding-group-content">
            <div class="active-modal-bots">
                <span v-for="(bot, index) in c_activeModalBots" :key="index">
                    <span v-if="index != 0">、</span>{{ bot.name }}</span
                >
                <span
                    @click="
                        bindBotV2(
                            'eWx',
                            'edit',
                            JSON.parse(dingdingGroupInfoStr)
                        )
                    "
                    class="el-icon-s-tools"
                ></span>
            </div>
            <div class="modal-list">
                <div class="bindingding-group-list">
                    <div class="itemHeader">
                        <div class="companyItem">
                            <div class="companyItemName">{{$t('botLink.dingGroupListDrawer.name')}}</div>
                            <div class="companyItemName">{{$t('botLink.dingGroupListDrawer.id')}}</div>
                            <div class="companyItemTime">{{$t('botLink.dingGroupListDrawer.bindTime')}}</div>
                            <div class="companyItemOperition">{{$t('botLink.operation')}}</div>
                            <div class="bindBot">{{$t('botLink.bindBot')}}</div>
                            <div class="reVisible">
                                <span>{{$t('botLink.dingGroupListDrawer.isItVisible')}}</span>
                                <el-switch
                                    v-model="replyVisible"
                                    @change="changeBatchSwitch"
                                    active-color="#366AFF"
                                    inactive-color="#A9B3C6"
                                >
                                </el-switch>
                            </div>
                        </div>
                    </div>
                    <div class="itemContent">
                        <div
                            class="companyItem"
                            v-for="item in dingdingGroupList"
                            :key="item.id"
                        >
                            <div class="companyItemName">
                                {{ item.dingTalkGroupInfo.groupName }}
                            </div>
                            <div class="companyItemName">
                                {{ item.openConversationId }}
                            </div>
                            <div class="companyItemTime">
                                {{
                                    new Date(item.updateTime).Format(
                                        "yyyy-MM-dd hh:mm:ss"
                                    )
                                }}
                            </div>
                            <div class="companyItemOperition">
                                <el-link
                                    @click="postDingdingGroupUnbind(item.id)"
                                    type="primary"
                                    class="unbind"
                                    >{{$t('botLink.unBind')}}
                                </el-link>
                            </div>
                            <div class="bindBot">
                                <span
                                    v-for="(cell, cellIndex) in item.bindBot
                                        ? item.bindBot.botInfos
                                        : []"
                                    :key="cell.botId"
                                    class="bindBotName"
                                >
                                    <span v-if="cellIndex != 0">、</span
                                    >{{ cell.name }}</span
                                >
                                <span
                                    style="color: #366aff"
                                    v-if="
                                        item.bindBot && item.bindBot.count != 0
                                    "
                                    class="editBindBot el-icon-s-tools"
                                    @click="bindBotV2('eWx', 'edit', item)"
                                ></span>
                                <el-button
                                    v-if="
                                        item.bindBot == null ||
                                        (item.bindBot &&
                                            item.bindBot.count == 0)
                                    "
                                    size="mini"
                                    type="primary"
                                    @click="bindBotV2('eWx', 'add', item)"
                                    >{{$t('botLink.dingGroupListDrawer.placeholderBindBot')}}</el-button
                                >
                            </div>
                            <div class="reVisible">
                                <el-switch
                                    @change="changeSingSwitch($event, item)"
                                    v-model="item.replyVisible"
                                    active-color="#366AFF"
                                    inactive-color="#A9B3C6"
                                >
                                </el-switch>
                            </div>
                        </div>
                    </div>
                    <div
                        class="itemContent"
                        v-show="
                            dingdingGroupList == null ||
                            dingdingGroupList.length == 0
                        "
                    >
                        <br />
                        <p class="null" style="text-align: center">{{$t('botLink.dingGroupListDrawer.emptyText')}}</p>
                        <br />
                    </div>
                </div>
                <div class="pagination-content">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["dingdingGroupInfoStr", "tamplateId", "replyVisible"],
    data() {
        return {
            dingdingGroupList: [],
            activeModalBots: JSON.parse(this.dingdingGroupInfoStr).bindBot
                .botInfos,
            pageSize: 20, // 每叶数量
            currentPage: 1, // 当前分页
            total: 0, // 列表数据总数
            allSwitchValue: "", // 回复是否全员可见总开关
        };
    },
    computed: {
        c_activeModalBots() {
            return JSON.parse(this.dingdingGroupInfoStr).bindBot.botInfos;
        },
    },
    methods: {
        changeBatchSwitch(val) {
            this.FetchPut2(
                this.requestUrl.dingdingGroup.dingtalkGroupBatchChange +
                    "/" +
                    this.tamplateId +
                    "?visible=" +
                    val
            ).then((res) => {
                if (res.code === "0") {
                    this.getDingdingGroupBindList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeSingSwitch(val, obj) {
            let bindId = obj.id;
            this.FetchPut2(
                this.requestUrl.dingdingGroup.dingtalkGroupChange +
                    "/" +
                    bindId +
                    "?visible=" +
                    val
            ).then((res) => {
                if (res.code === "0") {
                    this.getDingdingGroupBindList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        asynToAllGroup() {
            let bindId = JSON.parse(this.dingdingGroupInfoStr).id;
            this.FetchPost(
                this.requestUrl.dingdingGroup.sync2group + "?bindId=" + bindId,
                {
                    bindId: bindId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success(this.$t('botLink.dingGroupListDrawer.syncSuccess'));
                    this.currentPage = 1;
                    this.getDingdingGroupBindList();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        bindBotV2(appType, handleType, item) {
            this.$emit("bindBotV2", appType, handleType, item, "groupList");
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getDingdingGroupBindList();
        },
        postDingdingGroupUnbind(id) {
            this.$confirm(this.$t('botLink.dingGroupListDrawer.unBindMsg'))
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success(this.$t('botLink.dingGroupListDrawer.unBindSuccess'));
                            this.getDingdingGroupBindList();
                            this.$emit("getGroupTemplateList");
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 获取钉钉群绑定列表
        getDingdingGroupBindList() {
            this.FetchGet(
                this.requestUrl.dingdingGroup.getDingdinggroupByTemplate +
                    this.tamplateId,
                {
                    page: this.currentPage,
                    pageSize: this.pageSize,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.dingdingGroupList = [...res.data.list];
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
    mounted() {
        // this.getDingdingGroupBindList();
    },
};
</script>
<style lang="less" scoped>
#dingding-group-list {
    height: calc(100vh - 78px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .dingding-group-header {
        flex: none;
        padding: 0 24px;
        display: flex;
        .dingding-group-header-des {
            flex: auto;
            overflow: hidden;
        }
        .group-list-des {
            display: inline-block;
            margin-left: 8px;
            font-size: 12px;
            color: #909399;
        }
    }
    .dingding-group-content {
        flex: auto;
        padding: 18px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .active-modal-bots {
            flex: none;
            border-radius: 5px;
            padding: 16px;
            background-color: whitesmoke;
            .el-icon-s-tools {
                display: inline-block;
                margin-left: 8px;
                height: 20px;
                line-height: 20px;
                color: #366aff;
                cursor: pointer;
            }
        }
        .modal-list {
            flex: auto;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            .bindingding-group-list {
                flex: auto;
                display: flex;
                flex-direction: column;
                background-color: #f2f2f2;
                border-radius: 5px;
                overflow-y: auto;
                .itemHeader {
                    display: flex;
                    flex-direction: column;
                    .companyItem {
                        display: flex;
                        flex-direction: row;
                        padding: 10px 10px;
                        border-bottom: 1px solid #d7d7d7;
                        text-align: center;
                        .companyItemName {
                            width: 15%;
                        }
                        .companyappid {
                            width: 15%;
                        }
                        .companyItemTime {
                            width: 20%;
                        }
                        .companyItemOperition {
                            width: 15%;
                        }
                        .bindBot {
                            width: 15%;
                        }
                        .reVisible {
                            width: 20%;
                            display: flex;
                            justify-content: space-evenly;
                        }
                    }
                }
                .itemContent {
                    max-height: calc(100vh - 270px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    display: flex;
                    flex-direction: column;
                    .null {
                        text-align: center;
                        margin: 10px 0;
                        color: #808080;
                    }
                    .companyItem {
                        display: flex;
                        flex-direction: row;
                        padding: 10px 10px;
                        border-bottom: 1px solid #d7d7d7;
                        text-align: center;
                        .companyItemName {
                            width: 15%;
                            word-wrap: break-word;
                        }
                        .companyappid {
                            width: 15%;
                            word-wrap: break-word;
                        }
                        .companyItemTime {
                            width: 20%;
                        }
                        .companyItemOperition {
                            width: 15%;
                            .unbind {
                                margin: 0 5px;
                            }
                            .view {
                                margin: 0 5px !important;
                            }
                        }
                        .bindBot {
                            width: 15%;
                            .editBindBot {
                                cursor: pointer;
                                display: block;
                                text-align: center;
                            }
                        }
                        .reVisible {
                            width: 20%;
                        }
                    }
                }
            }
            .pagination-content {
                flex: none;
                text-align: center;
            }
        }
    }
}
</style>