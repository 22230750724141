<template>
    <div class="bind-askbot-client">
        <div :class="['qichat_drawer_content bind-askbot-client-content',componentsType === 'authoritySet' ? 'authoritySet' : '']">
            <div class="access-item" v-if="componentsType === ''">
                <div class="name">
                    <span class="stepNamber">1</span>
                </div>
                <div class="des">
                    <div class="bind-askbot-client-top">
                        <div class="evaluate-table-switch">
                            <el-switch
                                @click.native.prevent="changeBotIdentify('switch')"
                                :width="42"
                                v-model="enableBotRecognize"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2">>
                            </el-switch>
                            <span
                                class="switch-open-icon"
                                @click="changeBotIdentify('open')"
                                v-if="enableBotRecognize">
                                <i class="iconfont guoran-a-16-17"></i>
                            </span>
                            <span
                                class="switch-close-icon"
                                @click="changeBotIdentify('close')"
                                v-if="!enableBotRecognize">
                                <i class="arsenal_icon arsenalcuo1"></i>
                            </span>
                        </div>
                        <span class="bind-askbot-client-tips-text">{{$t('botLink.askbotClientDrawer.text')}}</span>
                    </div>
                    <div class="bind-askbot-client-bind-bot">
                        <div class="bind-askbot-client-bind-bot-title">
                            <div class="bind-askbot-client-bind-bot-title-left">{{$t('botLink.bindBot')}}</div>
                            <div>{{$t('botLink.consultationScope')}}</div>
                        </div>
                        <div class="bind-askbot-client-bind-bot-content">
                          <bind-bot
                              :userAttributeOptions="userAttributeOptions"
                              :attrRelations="attrRelations"
                              :tagsOptions="tagsOptions"
                              :bindBotList="bindBotList"
                              :appCombineNoBindBots="appCombineNoBindBots"
                              :isOpenData="isOpenData()"
                              @saveSetting="saveSetting"
                              @openStaffPopup="openStaffPopup"
                              @delBindBot="delBindBot"
                          ></bind-bot>
                          <template v-if="false">
                            <template v-if="bindBotList.length > 0">

                                <div :class="['bind-askbot-client-bind-bot-item condition-bind-askbot-client-bind-bot']"  v-for="(item,index) in bindBotList" :key="index">
                                        <div class="del-bind-bot">
                                            <i v-if="item.botType != -1" class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delBindBot(item,index,'')"></i>
                                            <span v-else> </span>
                                        </div>
                                        <div class="choose-bind-bot">
                                            <!-- <el-select v-model="item.botId" placeholder="请选择机器人" @change="chooseBot(item,$event)">
                                                <el-option
                                                    v-for="v in botList"
                                                    :key="v.id"
                                                    :label="v.name"
                                                    :value="v.id">
                                                </el-option>
                                            </el-select> -->
                                            <span class="choose-bind-bot-plugintool-name" v-if="item.botType == -1">
                                                <span>{{item.name}}</span>
                                            </span>
                                            <el-cascader v-else v-model="item.botId" :options="appCombineNoBindBots" :props="optionProps" @change="chooseBot(item,$event,index)" filterable>
                                            </el-cascader>
                                        </div>
                                        <!-- 新版本选择咨询范围---增加了条件 -->
                                        <div class="right-consultation-scope">
                                            <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in item.conditions" :key="conditionsIndex">
                                                <div class="right-consultation-scope-top-content">
                                                    <div class="select-cell-attr select-cell-inner">
                                                        <el-popover
                                                            :ref="'condition-popover'+conditionsIndex+index"
                                                            placement="bottom-start"
                                                            width="300"
                                                            v-model="conditionsItem.visible"
                                                            popper-class="choose-conditions-poprver"
                                                            :visible-arrow="false">
                                                            <div
                                                                :class="['choose-user-attrible one-column-ellipsis']"
                                                                slot="reference">
                                                                <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">{{$t('botLink.label')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'personalJobLevel'">{{$t('botLink.personalJobLevel')}}</span>
                                                            </div>
                                                            <el-tree
                                                                style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                                                                :data="userAttributeOptions"
                                                                :props="{
                                                                    children: 'children',
                                                                    label: 'label',
                                                                    id:'value',
                                                                }"
                                                                default-expand-all
                                                                node-key="id"
                                                                @node-click="(data) => onNodeClickCondition(data,conditionsItem,item,conditionsIndex,index)"
                                                                ref="userAttributeTree">
                                                                <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']" slot-scope="{node,data}">
                                                                    <span class="custom-tree-node-left">
                                                                        <span class="data-label">{{ data.label }}</span>
                                                                    </span>
                                                                </span>
                                                            </el-tree>
                                                        </el-popover>
                                                    </div>
                                                    <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
                                                        <el-select
                                                            size="small"
                                                            v-model="conditionsItem.relation"
                                                            placeholder="请选择类型"
                                                            @change="saveSetting">
                                                            <el-option
                                                                v-for="item in attrRelations"
                                                                :key="item.value"
                                                                :label="$t('common.' + item.value)"
                                                                :value="item.value">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                    <!-- 弹框选择组织架构 -->
                                                    <div
                                                        @click="openStaffPopup(item, index,'bindBot',conditionsItem,conditionsIndex)"
                                                        class="select-cell-attrv select-cell-inner one-column-ellipsis"
                                                        v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
                                                        <!-- <span
                                                            v-if="type.length != 0 && userAttributeNameValue.length == 0"
                                                            class="select-cell-attrv-placeholder">
                                                            请选择值
                                                        </span> -->
                                                        <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                                                            <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                                                                <span class="have-checked-intent"  style="cursor: pointer;color:#606266;" v-for="(tagCell, tagCellIndex) in conditionsItem.expect"  :key="tagCellIndex">
                                                                    <span v-if="isOpenData(tagCell.name)">
                                                                        <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                                    </span>
                                                                    <span v-else>{{tagCell.name }}</span>
                                                                    <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                                                                </span>
                                                            </template>
                                                            <div class="placeholder-text" v-else style="cursor: pointer;color:#606266;">{{$t('common.selectPlaceholder')}}</div>
                                                        </template>
                                                            <!-- 下拉选择自定义字段的值 -->
                                                        <el-select
                                                            class="choose-tag-select"
                                                            v-else
                                                            size="small"
                                                            v-model="conditionsItem.expect"
                                                            filterable
                                                            multiple
                                                            collapse-tags
                                                            :placeholder="$t('botLink.inputOrSelect')"
                                                            @change="saveSetting">
                                                            <el-option
                                                                v-for="item in tagsOptions"
                                                                :key="item.id"
                                                                :label="item.label"
                                                                :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div :class="['del-ondition',isFwh ? 'ml' : '']">
                                                    <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delCondition(item,conditionsItem,conditionsIndex)"></i>
                                                </div>
                                            </div>
                                            <div class="add-condition-bot-btn">
                                                <el-button @click="addCondition(item,conditionsItem)" size="small" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                                                <span  @click="addCondition(item,conditionsItem)">{{$t('botLink.addCondition')}}</span>
                                            </div>

                                        </div>
                                        <div v-if="!isMnDdLimit && isFwh" :class="['del-bind-bot',isFwh ? 'ml' : '']">
                                            <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delBindBot(item,index,'')"></i>
                                        </div>
                                    </div>
                            </template>
                            <div v-else class="bind-askbot-client-bind-no-data">{{$t('botLink.noBindBot')}}</div>
                          </template>
                        </div>
                    </div>
                    <div class="add-bind-bot-btn">
                        <el-button @click="addBindBot" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                        <span  @click="addBindBot" >{{$t('botLink.bindBot')}}</span>
                    </div>
                </div>
            </div>
            <div class="access-item">
                <div class="name" v-if="componentsType === ''">
                    <span class="stepNamber">2</span>
                </div>
                <div class="des">
                    <div class="bind-askbot-client-top">
                        <div class="evaluate-table-switch">
                            <el-switch
                                @click.native.prevent="changeKnowledgeSearch('switch')"
                                :width="42"
                                v-model="enableKnowledgeSearch"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2">
                            </el-switch>
                            <span
                                class="switch-open-icon"
                                @click="changeKnowledgeSearch('open')"
                                v-if="enableKnowledgeSearch">
                                <i class="iconfont guoran-a-16-17"></i>
                            </span>
                            <span
                                class="switch-close-icon"
                                @click="changeKnowledgeSearch('close')"
                                v-if="!enableKnowledgeSearch">
                                <i class="arsenal_icon arsenalcuo1"></i>
                            </span>
                        </div>
                        <span class="bind-askbot-client-tips-text">{{$t('botLink.enterpriseKnowledgeSearch')}}</span>
                    </div>
                    <identification-condition ref="identification" :isAskBot="true" @saveKnowledgeScope="saveKnowledgeScope"></identification-condition>
                    <template v-if="false">
                      <div class="bind-askbot-client-bind-bot no-border">
                        <div class="bind-askbot-client-bind-bot-titles">可使用范围</div>
                      </div>
                      <div class="input-divs need-mt" @click="openStaffPopup('','','knowledge')">
                        <div v-if="memberInfo4Client.clientInfo && memberInfo4Client.clientInfo.length > 0"
                             class="one-column-ellipsis  knowledge-one-column-ellipsis">
                              <span class="have-checked-intent" v-for="(item, index) in memberInfo4Client.clientInfo"
                                    :key="index">
                                  <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                                  <span class="data-label" v-if="item.selfType === 0 || item.selfType === 1 || item.ewechat">
                                      <span v-if="!item.ewechat">{{ item.label }}</span>
                                      <span v-else>
                                          <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'" :openid="item.label"></open-data>
                                      </span>
                                  </span>
                                  <span v-else>{{ item.name }}</span>
                                  <span v-if="index + 1 < memberInfo4Client.clientInfo.length">,</span>
                              </span>

                        </div>
                        <div class="placeholder-text" v-else>全部</div>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </template>
                </div>
            </div>
            <div class="access-item" v-if="componentsType === ''">
                <div class="name">
                    <span class="stepNamber">3</span>
                </div>
                <div class="des">
                    <div class="add_welcome_message">
                        <el-button @click="addWelMessage" type="primary" icon="iconfont guoran-tongyichicun-huanyingyu">
                            <span >{{$t('botLink.setWelcome')}}</span>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 选择咨询者 -->
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">{{$t('botLink.dialogMemberTitle')}}</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style" v-if="staffCustomerType === 'knowledge'">
                    <div>{{$t('botLink.dialogMemberTip')}}</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" label="all" >{{$t('botLink.askbotClientDrawer.all')}}</el-radio>
                        <el-radio v-model="chooseStyle" label="self" >{{$t('botLink.askbotClientDrawer.custom')}}</el-radio>
                    </div>
                </div>
                <staffCustomer 
                    dataType="staff"
                    v-if="chooseStyle === 'self'" 
                    :isMultiple="true" 
                    @onNodeClick="onNodeClick" 
                    :memberInfo4Client="staffCustomerType === 'bindBot' ? activeItem.memberInfo4Client : memberInfo4Client"
                    ref="staffCustomer"></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelChooseMember" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" class="confirm-btn">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import OpenData from "@/components/openData";
import Popup from '../../../components/popup.vue';
import staffCustomer from '@/components/staffCustomer.vue'
import BindBot from "./bindBot";
import IdentificationCondition from "../../../components/identificationCondition";
export default {
    data(){
        return {
            appCombineNoBindBots: [],

            enableBotRecognize:true, // 是否开启机器人识别
            enableKnowledgeSearch:true, // 是否开启企业知识智能搜索
            bindBotList:[],
            options:[],
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            selectClient:[],
            checkedList:[],
            chooseStyle: "",
            chooseStyleOld:"all",
            bindDetail:{},
            dialogMemberTree:false,
            activeItem:{},
            activeIndex:-1,
            staffCustomerType:"bindBot",
            activeConditionsIndex:0,

            optionProps: {disabled: 'isChecked', label: "name", value: 'id', emitPath: false},
        }
    },
    components:{ IdentificationCondition, BindBot, OpenData,Popup, staffCustomer},
    props:{
        botList:{
            type:Array,
            default(){
                return []
            }
        },
        combineNoBindBots:{
            type:Array,
            default(){
                return []
            }
        },
        componentsType:{
            type:String,
            default:''
        },
        userAttributeOptions:{
            type:Array,
            default(){
                return []
            }
        },
        attrRelations:{
            type:Array,
            default(){
                return []
            }
        },
        tagsOptions:{
            type:Array,
            default(){
                return []
            }
        },
        orgTree:{
            type:Array,
            default(){
                return []
            }
        }
    },
    methods:{
        // 选择待选机器人
        handleCheckedBotsChange(value, item, type) {
            console.log(this.bindBotList);
            console.log(value, item, type)
            
            let botType = "";
            if(type !== 'del'){
                
                // 新增 知识机器人
                this.appCombineNoBindBots.forEach(cBots => {
                    cBots.children.forEach( v =>{
                        if (value == v.id && !v.isChecked) {
                            item.apiKey = v.apiKey || "";
                            item.name = v.name;
                            item.botId = value;
                            item.isChecked = true;

                            botType = v.botType;

                            setTimeout(() => {
                                this.checkedBots = [];
                            }, 200);
                        }
                    })
                })
            }
        
            let ids = []
            this.bindBotList.forEach(item => {
                console.log(item);
                ids.push(item.botId)
                if(item.botId == value){
                    item.botType = botType;
                }
            })
            // 新增 知识机器人
            // this.appCombineNoBindBots.forEach(cBots => {
            //     cBots.children.forEach( allItem =>{
            //         if(ids.indexOf(allItem.id) !== -1){
            //             allItem.isChecked = true
            //         } else {
            //             allItem.isChecked = false
            //         }
            //     })
            // })
            console.log(this.appCombineNoBindBots);
        },
        //部门是否使用通讯录组件
        isOpenData(value){
        /* const res = /^\d+$/;
            console.debug('value',res.test(value))*/
            let bool = false
            if (this.orgTree.length > 1){
            if (this.orgTree[0].originData&&
                this.orgTree[0].originData.wechatBotDTO&&
                this.orgTree[0].originData.wechatBotDTO.bindType===0&&
                this.orgTree[0].originData.wechatBotDTO.wechatType===0){
                bool = true
            }
            else if (this.orgTree[1].originData&&
                this.orgTree[1].originData.wechatBotDTO&&
                this.orgTree[1].originData.wechatBotDTO.bindType===0&&
                this.orgTree[1].originData.wechatBotDTO.wechatType===0){
                bool = true
            }
            }
            else if (this.orgTree.length===1){
            if (this.orgTree[0].originData&&
                this.orgTree[0].originData.wechatBotDTO&&
                this.orgTree[0].originData.wechatBotDTO.bindType===0&&
                this.orgTree[0].originData.wechatBotDTO.wechatType===0){
                bool = true
            }
            }

            return bool
        },
        delCondition(item,conditionsItem,conditionsIndex){
            item.conditions.splice(conditionsIndex,1);
            this.saveSetting()
        },
         // 添加条件
        addCondition(item,conditionsItem){
            item.conditions.push({
                visible:false,
                type:'ALL',
                expect:[],
                key:"",
                relation:"CONTAIN_ANY",
            })
            this.saveSetting()
        },
        // 绑定机器人时的可咨询范围增加条件 start
        onNodeClickCondition(data,conditionsItem,item){
            // console.log(data,conditionsItem,item);
            conditionsItem.visible = false;
            if(data.value === "STAFF") {
                conditionsItem.visible = true;
                return false
            };
            conditionsItem.type = data.value;
            conditionsItem.key = '';
            conditionsItem.expect = [];
            if(data.value === 'CUSTOMIZE'){
                conditionsItem.key = data.key;
            }
            this.saveSetting()
        },
        // 获取已经绑定的信息
        getClientBindInfo(callback){
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + '21').then(res => {
                if (res.code === "0" && res.data) {
                    let datas = res.data[0];
                    this.bindDetail = datas;
                    if(datas){
                        datas.bindBot.botInfos.forEach(item => {
                            //   "type": // 范围对应类型 1：部门 0：成员
                            //    "source" : // 数据来源 MEMBER（员工），UPDOWN（上下游）
                            //  selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                            // scope, // 咨询范围 0：所有人 1：指定范围
                            if(item.scope === 1){
                                item.items.forEach(v => {
                                    v.label = v.name;
                                    if(v.source === 'MEMBER' && v.type === 0){
                                        v.selfType = 1;
                                    } else if(v.source === 'MEMBER' && v.type === 1){
                                        v.selfType = 0;
                                    } else if(v.source === 'UPDOWN' && v.type === 0){
                                        v.selfType = 3;
                                    } else if(v.source === 'UPDOWN' && v.type === 1){
                                        v.selfType = 2;
                                    }
                                })
                                item.memberInfo4Client = {
                                    clientInfo: item.items
                                }
                            } else {
                                item.memberInfo4Client = {
                                    clientInfo: []
                                }
                            }
                            
                        })

                        let tagListIds = [];
                        if (this.tagsOptions && this.tagsOptions.length != 0) {
                            tagListIds = this.tagsOptions.map(item => item.id);
                        }

                        this.bindBotList = datas && datas.bindBot.botInfos || [];
                        this.enableBotRecognize = datas.extInfo.enableBotRecognize;
                        if (datas.knowledgeScope){
                          this.enableKnowledgeSearch = datas.knowledgeScope.enableKnowledgeSearch;
                          if (datas.knowledgeScope.conditions){
                            datas.knowledgeScope.conditions.forEach(conditionCell => {
                                if(conditionCell.key == 'label'){
                                    if (conditionCell.expect && conditionCell.expect.length != 0) {
                                        conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                                        conditionCell.expect = [...new Set(conditionCell.expect)];
                                    } else {
                                        conditionCell.expect = [];
                                    }
                                }
                            })
                            this.$refs.identification.pluginCondition = JSON.parse(JSON.stringify(datas.knowledgeScope.conditions))
                          }
                          if (datas.knowledgeScope.knowledgeScopeConditions){
                            this.$refs.identification.$refs.dataSet.dataSetCondition = JSON.parse(JSON.stringify(datas.knowledgeScope.knowledgeScopeConditions))
                          }
                          if (datas.knowledgeScope.pluginToolInfo){
                            this.$refs.identification.pluginToolInfo = JSON.parse(JSON.stringify(datas.knowledgeScope.pluginToolInfo))
                          }
                        }
                        if (datas.extInfo.knowledgeSearchItems){
                          datas.extInfo.knowledgeSearchItems.forEach(item => {
                            item.label = item.name;
                            // type 1：部门 0：成员
                            // selfType  0 部门  1 员工
                            item.selfType = item.type == 1 ? 0 : 1
                          })
                        }
                        this.memberInfo4Client.clientInfo = datas.extInfo.knowledgeSearchItems || [];

                        
                        let ids = []
                        this.bindBotList.forEach(item => {
                            console.log(item);
                            ids.push(item.botId)
                            // if(item.botId == value){
                            //     item.botType = botType;
                            // }

                            // 过滤已删除标签
                            item.conditions.forEach(conditionCell => {
                                if(conditionCell.key == 'label'){
                                    if (conditionCell.expect && conditionCell.expect.length != 0) {
                                        conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                                        conditionCell.expect = [...new Set(conditionCell.expect)];
                                    } else {
                                        conditionCell.expect = [];
                                    }
                                }
                            })
                        })
                        this.appCombineNoBindBots.forEach(cBots => {
                            cBots.children.forEach( allItem =>{
                              //判断是知识机器人时，设置plugins字段
                                if (cBots.id == "1" && ids.indexOf(allItem.id) !== -1){
                                  let index = ids.indexOf(allItem.id)
                                  if (!this.bindBotList[index].plugins){
                                    if (allItem.llmBot && allItem.llmBot.plugins){
                                      allItem.llmBot.plugins.forEach(pluginItem => {
                                        console.log('pluginItem',pluginItem,index)
                                        pluginItem.pluginTools.forEach((pluginTools) => {
                                          let obj = {
                                            id: pluginTools._id,
                                            name: pluginTools.name,
                                            scope: 0, // 咨询范围 0：所有人 1：指定范围
                                            parentBotId: allItem.id,
                                            memberInfo4Client: {clientInfo: []},
                                            checkedList:[],
                                            conditions:[{
                                              visible:false,
                                              userAttributeTypeName:"所有人",
                                              type:'ALL',
                                              expect:[],
                                              key:"",
                                              relation:"CONTAIN_ANY",
                                            }]
                                          }
                                          if (!this.bindBotList[index].plugins){
                                            this.bindBotList[index].plugins = []
                                          }
                                          this.bindBotList[index].plugins.push(obj)
                                        })
                                      })
                                    }
                                  } else {
                                    console.log('plugins',this.bindBotList[index].plugins)
                                  }
                                }
                            })
                        })
          
                        if(callback) {
                            callback(this.bindDetail)
                        }
                    }
                }
            });
        },
        // 修改机器人识别开关
        changeBotIdentify(value){
            if(value === 'open'){
                this.enableBotRecognize = false;
            } else if (value === 'close'){
                this.enableBotRecognize = true;
            } 
            this.saveSetting();
        },
        // 修改企业知识智能搜索开关
        changeKnowledgeSearch(value){
            if(value === 'open'){
                this.enableKnowledgeSearch = false;
            } else if (value === 'close'){
                this.enableKnowledgeSearch = true;
            } 
            // this.saveSetting();
            this.saveKnowledgeScope();
        },
        addBindBot(){
            this.bindBotList.push({
                botId:"",
                name:'',
                apiKey:"",
                scope: 0, // 咨询范围 0：所有人 1：指定范围
                items:[],
                botType:1,
                memberInfo4Client: {clientInfo: []},
                checkedList:[],
                conditions:[{
                    visible:false,
                    userAttributeTypeName:"所有人",
                    type:'ALL',
                    expect:[],
                    key:"",
                    relation:"CONTAIN_ANY",
                }]
            })
            this.saveSetting();
        },
        delBindBot(item,index){
            console.log(552 ,item);
                

                if (item.botType == 10) {
                    let tampBindBotList = JSON.parse(JSON.stringify(this.bindBotList));
                    let arr = [];
                    tampBindBotList.forEach((cell, cellIndex) => {
                        console.log(559, cell);
                        if (item.botId != cell.botId && item.botId != cell.parentBotId) {
                            arr.push(cell);
                        }
                    })
                    this.bindBotList = [...arr];
                } else {
                    this.bindBotList.splice(index,1);
                }

                
                
                this.saveSetting();

                let copyItem = JSON.parse(JSON.stringify(item))
                this.handleCheckedBotsChange(copyItem.botId, copyItem,'del')
            // }
        },
        splitArray(arr, startIndex, endIndex) {
            const firstPart = arr.slice(startIndex, endIndex);
            const secondPart = arr.slice(endIndex);
            return [firstPart, secondPart];
        },
        chooseBot(item,value, index){
            console.log(560, item, item.botType, index);

            this.botList.forEach(_item => {
                if(_item.id === value){
                    item.name = _item.name;
                    item.apiKey = _item.apiKey;
                }
            })
            this.appCombineNoBindBots.forEach(cell =>{
                cell.children.forEach(cBot => {
                    if(cBot.id == value) {
                        item.botType = cBot.type;
                    }
                })
            })
            this.handleCheckedBotsChange(value, item);

            // 知识机器人 将机器人下插件工具列表渲染至页面中
            if ( false && item.botType == 10) {
                console.log("item.botType == 10", value);
                let tampPluginsOptions = [];
                this.appCombineNoBindBots[1].children.forEach(cell => {
                    
                    if (cell.id == value) {
                        if(cell.llmBot.plugins != null){
                            cell.llmBot.plugins.forEach(pluginCell => {
                                if(pluginCell.pluginTools != null) {
                                    pluginCell.pluginTools.forEach(pluginToolsCell => {
                                        tampPluginsOptions.push(
                                            {
                                                botId: pluginToolsCell._id,
                                                name: pluginCell.name + ' - ' + pluginToolsCell.name,
                                                apiKey:"",
                                                scope: 0, // 咨询范围 0：所有人 1：指定范围
                                                items: [],
                                                botType: '-1',
                                                parentBotId: value,
                                                memberInfo4Client: {clientInfo: []},
                                                checkedList:[],
                                                conditions:[{
                                                    visible:false,
                                                    userAttributeTypeName:"所有人",
                                                    type:'ALL',
                                                    expect:[],
                                                    key:"",
                                                    relation:"CONTAIN_ANY",
                                                }]
                                            }
                                        )
                                    })
                                }

                            })
                        }
                    }
                })
        
                let tampBindBotList = JSON.parse(JSON.stringify(this.bindBotList));
                let [tampBindBotListP1, tampBindBotListP2] = this.splitArray(tampBindBotList, 0, index + 1);
                tampBindBotList = tampBindBotListP1.concat(tampPluginsOptions).concat(tampBindBotListP2);

                this.bindBotList = [...tampBindBotList];
            }
            
            // 筛选出botIds botType == 10 的 
            let botIds10 = [];
            this.bindBotList.forEach(item => {
                if(item.botType == 10) {
                    botIds10.push(item.botId);
                }
            })
            let tampBindBotList = [];
            this.bindBotList.forEach(item => {
                if(item.botType == -1 && botIds10.indexOf(item.parentBotId) == -1){
                    // 由知识机器人切换为普通的机器人
                } else {
                    tampBindBotList.push(item);
                }
            })
            this.bindBotList = [...tampBindBotList];
            

            this.saveSetting();
        },
        openStaffPopup(item,index,type,conditionsItem,conditionsIndex) {
            this.activeConditionsIndex = conditionsIndex;
            // console.log(this.staffCustomerType,item,conditionsItem,conditionsIndex,'item');
            this.activeItem = JSON.parse(JSON.stringify(item));
            this.activeIndex = index;
            this.staffCustomerType = type;
            if(this.staffCustomerType === 'bindBot'){
                this.activeItem.checkedList = JSON.parse(JSON.stringify(conditionsItem.expect));
                // this.chooseStyle = item.memberInfo4Client.clientInfo.length > 0 ? 'self' : 'all';
                // if (item.items == null) {
                //     item.items = [];
                // }
                // item.items.forEach((_item) => {
                //     arr.push(_item.id);
                // })
                this.activeBindBotsItems = conditionsItem.expect;
                this.chooseStyle = 'self'
                this.activeItem.memberInfo4Client.clientInfo = this.activeItem.conditions[this.activeConditionsIndex].expect
            } else {
                this.checkedList = JSON.parse(JSON.stringify(this.memberInfo4Client.clientInfo));
                this.chooseStyle = this.memberInfo4Client.clientInfo.length > 0 ? 'self' : 'all'
            }
            this.dialogMemberTree = true;
        },
        onNodeClick(node, type, memberInfo) {
            let obj = JSON.parse(JSON.stringify(node))
            obj.name = obj.label || obj.name;
            let AddObj = {
                id:obj.id,
                name:obj.label,
                type:obj.type,
                isWeWorkThirdData:obj.isWeWorkThirdData
            }
            if (obj.checked) {
                obj.ewechat = obj.staffType === 'staff-t' ? true : false;
                if(this.staffCustomerType === 'bindBot'){
                    if(this.activeItem.checkedList && this.activeItem.checkedList.length > 0){
                        this.activeItem.checkedList.push(AddObj);
                    } else {
                        this.activeItem.checkedList = [AddObj];
                    }
                    this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList)
                } else {
                    this.checkedList.push(AddObj);
                    this.checkedList = this.handlerArr(this.checkedList)
                }
            } else {
                if(this.staffCustomerType === 'bindBot'){
                    this.activeItem.checkedList.forEach((v, i) => {
                        if (obj.id == v.id) {
                            this.activeItem.checkedList.splice(i, 1)
                        }
                    })
                    this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList)
                } else {
                    this.checkedList.forEach((v, i) => {
                        if (obj.id == v.id) {
                            this.checkedList.splice(i, 1)
                        }
                    })
                    this.checkedList = this.handlerArr(this.checkedList)
                }
            }
            this.$refs.staffCustomer.checkMemberList = []
            // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        cancelChooseMember(){
            this.chooseStyle = this.chooseStyleOld;
            this.dialogMemberTree = false;
        },
        preserStaff() {
            this.chooseStyleOld = this.chooseStyle;
            if (this.chooseStyle === 'all') {
                this.checkedList = [];
            }
            if(this.staffCustomerType === 'bindBot'){
                    this.bindBotList.forEach((item,index) => {
                        if(index === this.activeIndex){
                            if (this.chooseStyle === 'all') {
                                item.memberInfo4Client = {
                                    clientInfo:[]
                                }
                            } else {
                                item.conditions[this.activeConditionsIndex].expect = this.activeItem.checkedList || []
                                // item.memberInfo4Client = {
                                //     clientInfo:this.activeItem.checkedList || []
                                // }
                                
                            }
                        }
                    })
                // }
            } else  {
                this.memberInfo4Client.clientInfo = JSON.parse(JSON.stringify(this.checkedList)) || [];
            }
            this.dialogMemberTree = false;
            this.saveSetting();
        },
        saveSetting(){
            if(this.componentsType === 'authoritySet')return false
            let flag = true,conditionsFlag = true;
            console.log(this.bindDetail,'this.bindDetail');
            if(!this.bindDetail || !this.bindDetail.id){
                this.$message.warning('请选择需要绑定的机器人')
                flag = false
                return false;
            }
            let params = {
                bindBot:{
                    botInfos:[],
                    bindId:this.bindDetail.id,
                    bindCode:this.bindDetail.bindCode,
                },
                "enableBotRecognize": this.enableBotRecognize,     // 是否开启指定机器人识别
                "enableKnowledgeSearch":this.enableKnowledgeSearch,  // 是否开启企业知识智能搜索
                "knowledgeSearchItems": [] // 企业知识智能搜索指定范围对应的信息，结构同items
            };
            

            if(this.bindBotList.length === 0){
                params.bindBot.botInfos = [];
            } else {
                let tampPreBindBotList = [];
                this.bindBotList.forEach(bot => {
                    if (bot.botType !== undefined) {
                        tampPreBindBotList.push(bot);
                    } else {
                        // tampPreBindBotList.forEach(tampCell => {
                        //     if(tampCell.botId == bot.parentBotId) {
                        //         let tampBot = {
                        //             id: bot.botId,
                        //             name: bot.name.split(" - ")[1],
                        //             scope: 0,
                        //             conditions: bot.conditions
                        //         }
                        //         console.log(tampBot);
                        //     }
                        // })
                    }
                     
                })
                
                console.log(tampPreBindBotList);
                // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                tampPreBindBotList.forEach(item => {
                    if(!item.botId || item.botId === ''){
                        flag = false;
                    }
                    let items = [],scope = 0,conditionsCopy = [];
                    if(item.conditions && item.conditions.length > 0){
                        item.conditions.forEach(conditionsItem => {
                            if(conditionsItem.type == 'ALL'){
                                conditionsCopy.push({
                                    expect:[],
                                    type:conditionsItem.type,
                                    key:"",
                                    relation:null,
                                })
                            } else if(conditionsItem.type == 'STAFF_OR_DEPARTMENT'){
                                conditionsCopy.push({
                                    expect:conditionsItem.expect,
                                    type:conditionsItem.type,
                                    key:"",
                                    relation:conditionsItem.relation,
                                })
                            } else if(conditionsItem.type == 'CUSTOMIZE'){
                                conditionsCopy.push({
                                    expect:conditionsItem.expect,
                                    type:conditionsItem.type,
                                    key: conditionsItem.key,
                                    relation:conditionsItem.relation,
                                })
                            }
                        })
                    } else {
                        conditionsFlag = false;
                    }
                     
                    let obj = {
                        botId:item.botId,
                        name:item.name,
                        botType: item.botType == null?0:item.botType,
                        apiKey:item.apiKey,
                        scope: scope, // 咨询范围 0：所有人 1：指定范围
                        items:items,
                        conditions:conditionsCopy,
                        plugins:item.plugins ? item.plugins.map((res) => {
                          let obj = {
                            id:res.id,
                            name: res.name,
                            scope:res.scope,
                            conditions:res.conditions.map(ress => {
                              let conditions = {
                                expect:[],
                                type:ress.type,
                                key:"",
                                relation:null,
                              }
                              if(ress.type == 'ALL'){
                                conditions.type = ress.type
                              } else if(ress.type == 'STAFF_OR_DEPARTMENT'){
                                conditions.expect = ress.expect
                                conditions.type = ress.type
                                conditions.relation = ress.relation
                              } else if(ress.type == 'CUSTOMIZE'){
                                conditions.expect = ress.expect
                                conditions.type = ress.type
                                conditions.key =  ress.key;
                                conditions.relation = ress.relation
                              }
                              return conditions
                            })
                          }
                          return obj
                        }) : []
                    }
                    // if (obj.botType == 10) {
                    //     obj.plugins = item.plugins;
                    // }
                    params.bindBot.botInfos.push(obj)

                })
            }

            this.checkedList.forEach(v => {
                params.knowledgeSearchItems.push({
                    "id":v.id, // 范围对应ID
                    "type":v.selfType === 1 || v.selfType === 3 ? 0 : 1, // 范围对应类型 1：部门 0：成员
                    "name":v.name || v.label, // 范围对应名称
                    "source" :v.selfType === 2 || v.selfType === 3 ? 'UPDOWN' : 'MEMBER', // 数据来源 MEMBER（员工），UPDOWN（上下游）
                   ewechat:v.staffType === 'staff-t' ? true : v.ewechat
                })
            })
            if(!flag) {
                this.$message.warning('请选择需要绑定的机器人');
                return false;
            } 
            if(!conditionsFlag) {
                this.$message.warning('请至少保留一个条件');
                return false;
            } 
            this.FetchPost(this.requestUrl.bindV2.clientBindBot,params).then(res =>{
                if(res.code == '0' && res.data) {
                    this.$message.success('信息更新成功')
                }
            })
        },
        addWelMessage() {
            console.log("this.bindDetail: ", this.bindDetail);
            
            this.$emit('addWelMessage', this.bindDetail)
        },
        saveKnowledgeScope(){
          this.FetchPost(this.requestUrl.bindV2.knowledgeScope,{
            "bindId": this.bindDetail.id,
            "enableKnowledgeSearch": this.enableKnowledgeSearch,
            "knowledgeScopeConditions": this.$refs.identification.$refs.dataSet.dataSetCondition,
            "conditions": this.$refs.identification.pluginCondition,
            "pluginToolInfo": null
          }).then(res => {
            console.log('knowledgeScope',res)
            if (res.code == 0){
              this.$message.success('信息更新成功')
            }
          })
        }
    },
    mounted(){
        this.appCombineNoBindBots = JSON.parse(JSON.stringify(this.combineNoBindBots));

        console.log(this.appCombineNoBindBots);
    }
}
</script>

<style lang="less" scoped>
    .bind-askbot-client{
        .bind-askbot-client-content{
            .access-item{
                font-size: 14px;
                    // align-items: center;
                    .bind-askbot-client-tips-text{
                        margin-left: 20px;
                    }
                    .bind-askbot-client-top{
                        display: flex;
                        align-items: center;
                    }
                    .bind-askbot-client-bind-bot{
                        margin-top: 25px;
                        width: 100%;
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        &.no-border{
                            border: none;
                        }
                        .bind-askbot-client-bind-bot-titles{
                            height: 39px;
                            background: #F6F8FD;
                            border-radius: 5px 5px 0px 0px;
                            font-weight: bolder;
                            display: flex;
                            align-items: center;
                            padding: 0 10px 0 10px;
                            .bind-askbot-client-bind-bot-title-left{
                                width: 280px;
                                margin-right: 12px;
                            }
                        }
                        .bind-askbot-client-bind-bot-content{
                            padding: 16px 10px;
                            max-height: 500px;
                            overflow-x: hidden;
                            overflow-y: auto;
                        }
                        .bind-askbot-client-bind-no-data{
                            color: #606266;
                            text-align: center;
                        }
                    }
                    .input-divs{
                        flex: 0.5;
                        height: 36px;
                        background: #FFFFFF;
                        border: 1px solid #DCDFE6;
                        border-radius: 5px;
                        line-height: 36px;
                        display: flex;
                        align-items: center;
                        padding: 0 12px;
                        cursor: pointer;
                        color: #606266;
                        &.need-mt{
                            margin-top: 20px;
                        }
                        .placeholder-text{
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #606266;
                            display: flex;
                            align-items: center;
                            // justify-content: center;
                            width: 100%;
                        }
                        .have-checked-intent{
                            margin-right: 4px;
                        }
                        .el-icon-arrow-down{
                            font-size: 14px;
                            color: #717B90;
                            margin-left: 4px;
                            margin-right: 0;
                        }
                        .bind-bot-one-column-ellipsis{
                            text-align: left;
                           width: calc(100% - 24px);
                        }
                        .knowledge-one-column-ellipsis{
                            width: calc(100% - 24px);
                        }
                    }
                    .add-bind-bot-btn{
                        display: flex;
                        align-items: center;
                        
                        margin-top: 20px;
                        /deep/.el-button{
                            width: 34px;
                            height: 34px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                        }
                        span{
                            cursor: pointer;
                            color: #366AFF;
                        }
                    }
                    .add_welcome_message {
                        display: flex;
                        align-items: center;
                        /deep/.el-button{
                            // width: 34px;
                            height: 34px;
                            padding: 0 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                            border-radius: 34px;
                        }
                        span{
                            margin-left: 5px;
                            // cursor: pointer;
                            color: #FFFFFF;
                        }
                    }
            }
            &.authoritySet{
                height: 100% !important;
                .access-item{
                    justify-content: center;
                }
            }
        }
    }
</style>