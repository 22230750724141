<template>
    <div class="staff-customer">
        <div class="staff-customer-input">
            <el-input
                v-model="keyword"
                @keyup.enter.native="searchStaff"
                @input="searchInit"
                placeholder="请输入部门/员工名称，回车搜索"
                size="small"
            ></el-input>
        </div>
        <template v-if="dataType === 'staff'">
            <div v-for="(item, index) in treeData" :key="'ind' + index">
                <el-tree
                    key="selectOrganizationTreeLazy"
                    :data="[item]"
                    id="selectOrganizationTreeLazy"
                    class="filter-tree"
                    node-key="id"
                    :default-expanded-keys="defaultExpandedKeysMember"
                    :expand-on-click-node="false"
                    :ref="item.id + 'TreeLazy'"
                >
                    <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                        @click="onNodeClick(data, '', item, node)"
                    >
                        <span class="custom-tree-node-left" :class="data.dataType === 'hasNext' ?'loadMore':''">
                            <i
                                :class="[
                                    'el-icon-caret-right',
                                    data.showChildren ? 'down' : 'right',
                                ]"
                                v-show="data.type == 1"
                                @click.stop="nodeExpand(data, node)"
                            >
                            </i>
                            <span
                                class="data-label"
                                v-if="
                                    (item.originData &&
                                    item.originData.wechatBotDTO &&
                                    item.originData.wechatBotDTO.bindType === 0 &&
                                    item.originData.wechatBotDTO.wechatType === 0) || data.isWeWorkThirdData
                                "
                            >
                                <open-data
                                    :type="
                                        data.type == 1
                                            ? 'departmentName'
                                            : 'userName'
                                    "
                                    :openid="data.label"
                                ></open-data>
                            </span>
                            <span v-else class="data-label">{{ data.label }}</span>
                        </span>
                        <span v-show="data.checked">
                            <i :class="['el-icon-check']"></i>
                        </span>
                    </span>
                </el-tree>
            </div>
        </template>
        <template v-if="dataType === 'updown'">
            <el-tree
                class="updown-tree"
                :props="{
                    isLeaf: 'leaf',
                    label: 'name',
                    children: 'children',
                }"
                :data="updownDateTree"
                :load="loadNode"
                lazy
                :expand-on-click-node="false"
                ref="upDownTree"
                node-key="id">
               <span 
                    :class="['custom-tree-node',!data.wxOpenId && data.selfType===3 && !insertIm ? 'not-allowed' : 'allowed']"
                    slot-scope="{node,data}" @click="onNodeClick(data,'updown',data,node)">
                    <span class="custom-tree-node-left">
                        <span class="data-label">{{ data.name }}</span>
                    </span>
                    <span v-if="data.checked">
                        <i :class="['el-icon-check']"></i>
                    </span>
                </span>
            </el-tree>
        </template>
        
                
        <!-- <el-tabs v-model="activeName">
            <el-tab-pane label="选择员工" name="staff">
                <div v-for="(item, index) in treeData" :key="'ind' + index">
                    <el-tree
                        key="selectOrganizationTreeLazy"
                        :data="[item]"
                        id="selectOrganizationTreeLazy"
                        class="filter-tree"
                        node-key="id"
                        :default-expanded-keys="defaultExpandedKeysMember"
                        :expand-on-click-node="false"
                        :ref="item.id + 'TreeLazy'"
                    >
                        <span
                            class="custom-tree-node"
                            slot-scope="{ node, data }"
                            @click="onNodeClick(data, '', item, node)"
                        >
                            <span class="custom-tree-node-left">
                                <i
                                    :class="[
                                        'el-icon-caret-right',
                                        data.showChildren ? 'down' : 'right',
                                    ]"
                                    v-show="data.type == 1"
                                    @click.stop="nodeExpand(data, node)"
                                >
                                </i>
                                <span
                                    class="data-label"
                                    v-if="
                                        item.originData &&
                                        item.originData.wechatBotDTO &&
                                        item.originData.wechatBotDTO
                                            .bindType === 0 &&
                                        item.originData.wechatBotDTO
                                            .wechatType === 0
                                    "
                                >
                                    <open-data
                                        :type="
                                            data.type == 1
                                                ? 'departmentName'
                                                : 'userName'
                                        "
                                        :openid="data.label"
                                    ></open-data>
                                </span>
                                <span v-else class="data-label">{{
                                    data.label
                                }}</span>
                            </span>
                            <span v-show="data.checked">
                                <i :class="['el-icon-check']"></i>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </el-tab-pane>
            <el-tab-pane
                label="选择客户"
                name="customer"
                v-if="clientType === 'STAFFANDCUSTOMER'"
            >
                <div class="customer-list">
                    <div
                        class="customer-item"
                        v-for="item in selectClient"
                        :key="item.id"
                        @click="onNodeClick(item, 'customer', '')"
                    >
                        <span>{{ item.name }}</span>
                        <span v-if="item.checked"
                            ><i class="el-icon-check"></i
                        ></span>
                    </div>
                </div>
                <div
                    class="load-more-client"
                    v-if="hasClientNext"
                    @click="loadMore"
                >
                    <el-button type="text" size="small">查看更多</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="选择上下游" name="upDown" v-if="isShowUpDown">
                <el-tree
                    class="updown-tree"
                    :props="{
                        isLeaf: 'leaf',
                        label: 'name',
                        children: 'children',
                    }"
                    :data="updownDateTree"
                    :load="loadNode"
                    lazy
                    ref="upDownTree"
                    node-key="id"
                >
                    <span
                        :class="[
                            'custom-tree-node',
                            !data.wxOpenId ? 'disabled' : '',
                        ]"
                        slot-scope="{ node, data }"
                        @click="onNodeClick(data, 'updown', data, node)"
                    >
                        <span class="custom-tree-node-left">
                            <span class="data-label">{{ data.name }}</span>
                        </span>
                        <span v-if="data.checked">
                            <i :class="['el-icon-check']"></i>
                        </span>
                    </span>
                </el-tree>
            </el-tab-pane>
            <el-tab-pane label="选择所有客户" v-if="companyConfig">
                <div class="customer-list">
                    <div
                        class="customer-item"
                        @click="onNodeClick('', 'allCustomer', '')"
                    >
                        <span>选择所有客户{{ companyConfig }}</span>
                        <span
                            v-if="
                                memberInfo4Client.clientInfo.some((client) => {
                                    return client.id == 'allCustomer';
                                })
                            "
                            ><i class="el-icon-check"></i
                        ></span>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs> -->
    </div>
</template>

<script>
import OpenData from "@/components/openData";
export default {
    name: "staffCustomer",
    components: { OpenData },
    data() {
        return {
            activeName: "staff",
            defaultProps: {
                label: "label",
                children: "children",
                isLeaf: "leaf",
            },
            clientId: "",
            keyword: "",
            defaultExpand: false,
            checkMemberList: [],
            memberMapList: [],
            isLazy: false,
            defaultExpandedKeys: [],
            defaultExpandedKeysMember: [],
            treeData: [],
            updownDateTree: [],
            InternalTest: false,

            memberInfo4Client: {
                corpId: "",
                type: "",
                bindType: "",
                memberId: "",
                name: "",
                deptId: "",
                deptName: "",
            },
            memberList: [],
            clientType: "",
            selectClient: [],
            hasClientNext: false,
            companyConfig: false,

            selectDepts: [], // 选中的组织架构信息list
            selectUpDDepts: [], // 选中的上下游信息list
            selectIds: [],
            currentPage: 1,
            pageSize: 20,
        };
    },
    props: ["isMultiple", "isShowUpDown", "selectIdsP", "activeCorpId", "activeBindBotsItems","dataType"],
    created() {
        let that = this;
        if (
            that.memberList &&
            that.memberList[0] &&
            that.memberList[0].total < 300
        ) {
            that.defaultExpand = true;
        } else {
            that.defaultExpand = false;
        }
        this.InternalTest =
            [
                "86dc09da570948d2b4841122d732f373",
                "fb348d095c0b4fd7bbd37826563dac7d",
                "b633dfddeb1a424e939411384499c41b",
                // 'ab0f90737c8b4f2d85ba2157e4473110'
            ].some((_mainId) => {
                return _mainId == localStorage.getItem("_mainId");
            }) || process.env.NODE_ENV === "development";
    },
    mounted() {
        this.selectIds = this.selectIdsP;
        this.$nextTick(() => {
            this.memberMapList = this.memberList.map((item) => {
                return [item];
            });
            setTimeout(() => {
                this.ergodicClient("");
            }, 500);
            /*   setTimeout(()=>{
                    WWOpenData.bind(this.$el);
                },700)*/
        });
        this.selectDepts = [...this.activeBindBotsItems];
        this.getData(null);
    },
    methods: {
        searchInit(val) {
            if (val.trim() === "") {
                this.getData(null);
            }
        },
        //获取员工的树结构
        getMemberTree() {
            this.$http("/api/manager/corpListDynamicLazy", {
                query: {
                    departId: "",
                },
            }).then((res) => {
                if (res.data.code === "0") {
                    // 超过200条组织架构 会使用懒加载， 直接取第0项的 type判断是不是部门，及需不需要 懒加载
                    this.memberList = res.data.data;
                    this.$refs.staffCustomer &&
                        (this.$refs.users.isLazy = res.data.data[0].lazy);
                }
            });
        },
        nodeClickUpDown(data, node, el) {
            let obj = JSON.parse(JSON.stringify(data));
            let flag = obj.checked;
            // 成员
            if (data.organizationId) {
                this.handlerUpDown(this.updownDateTree, data);
                this.$set(data, "checked", !flag);
            }
        },
        // 递归获取所有节点
        handlerUpDown(moduleDataList, data) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        // if(data.id === moduleDataList[i].id){
                        // 	this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
                        // } else {
                        this.$set(moduleDataList[i], "checked", false);
                        // }
                        if (
                            moduleDataList[i].children &&
                            moduleDataList[i].children.length > 0
                        ) {
                            this.handlerUpDown(
                                moduleDataList[i].children,
                                data
                            );
                        }
                    }
                }
                resolve(true);
            });
        },
        //懒加载上下游员工
        loadNode(node, resolve) {
            console.log("懒加载");
            let uid = localStorage.getItem("_uid");
            if (node.level === 0) {
                let datas = [];
                let url =
                    "/portal-api/upAndDown/selectOrganizationById" +
                    "?userId=" +
                    uid;
                this.FetchGet(url).then((res) => {
                    console.log(res,'res');
                    datas = res.data || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                        element.type = "updown";
                    });
                    console.log(datas, "datasdatasdatasdatas");
                    this.updownDateTree = datas;
                    this.getAllNodeId(datas, "show");
                    return resolve(datas);
                });
            }
            if (node.level >= 1) {
                let datas = [];
                let userDatas = [];
                let url =
                    "/portal-api/upAndDown/selectOrganizationById" +
                    "?id=" +
                    node.data.id +
                    "&userId=" +
                    uid;
                this.FetchGet(url).then((res) => {
                    datas = res.data.data || [];
                    datas.forEach((element) => {
                        element.leaf = false;
                        element.checked = false;
                        element.type = "updown";
                    });
                    let departUrl =
                        "/portal-api/upAndDown/selectUpAndDownMemberByOrganizationId?organizationId=" +
                        node.data.id +
                        "&userId=" +
                        uid;
                    this.$http.get(departUrl).then((res) => {
                        console.log(res, "resresres");
                        userDatas = res.data.data;
                        userDatas.forEach((element) => {
                            element.leaf = true;
                            element.checked = false;
                            element.type = "updown";
                        });
                        datas = datas.concat(userDatas);
                        this.getAllNodeId(datas, "show");
                        node.data.children = datas;
                        return resolve(datas);
                    });
                });
            }
        },
        handleClick() {},

        loadMore() {
            this.$emit("loadMore");
        },
        // 节点被展开
        nodeExpand(data, node) {
            data.showChildren = !data.showChildren;
            if (data.showChildren) {
                this.getData(data.id, data, node);
            } else {
                data.children = [];
            }
        },
        // 获取员工数据
        getData(departId, data, node, isLoadMore) {
            let axiosDatas = [];
            let userId = localStorage.getItem("_uid");
            if (!isLoadMore) {
                this.currentPage = 1;
            }
            let url = departId
                ? "/portal-api/department/corpListLazy-v2" +
                  "?departId=" +
                  departId +
                  "&userId=" +
                  userId +
                  "&corpId=" +
                  this.activeCorpId + '&page=' + this.currentPage + '&size=' + this.pageSize
                : "/portal-api/department/corpListLazy-v2" +
                  "?userId=" +
                  userId +
                  "&corpId=" +
                  this.activeCorpId  + '&page=' + this.currentPage + '&size=' + this.pageSize;
            this.FetchGet(url).then((res) => {
                // let memberId = Number(this.memberInfo4Client.memberId);
                let ids = this.selectIds;
                axiosDatas = this.handlerCheckedSelf(
                    res.list || [],
                    ids,
                    departId ? departId : -1
                );

                this.getAllNodeId(axiosDatas, "show");
                if (departId) {
                    if (res.hasNextPage){
                        let info = {
                            label: "加载更多",
                            dataType: 'hasNext',
                            id: departId + 'hasNext',
                            parentId: departId,
                            currentPage: res.nextPage?res.nextPage:1
                        }
                        axiosDatas = axiosDatas.concat([info])
                    }
                    axiosDatas.forEach(element => {
                        if(element.type === 0 || element.dataType === 'hasNext') {
                            element.leaf = true
                        }
                    })
                    if (isLoadMore) {
                        let flagFind = true;
                        let findParent = (list) => {
                            if (flagFind) {
                                list.forEach(item => {
                                    if (item.id == departId) {
                                        flagFind = false;
                                        if (item.children[item.children.length - 1].dataType == 'hasNext') {
                                            item.children.length = item.children.length - 1;
                                        }
                                        item.children = [...item.children, ...axiosDatas];
                                        this.defaultExpandedKeysMember = [
                                            ...this.defaultExpandedKeysMember,
                                            ...[departId],
                                        ];
                                        item.showChildren = true;
                                    } else {
                                        findParent(item.children);
                                    }
                                })
                            }
                            
                        }
                        findParent(this.treeData);
                    } else {
                        data.children = [...data.children, ...axiosDatas];
                        this.defaultExpandedKeysMember = [
                            ...this.defaultExpandedKeysMember,
                            ...[departId],
                        ];
                        data.showChildren = true;
                    }
                } else {
                    this.treeData = [...axiosDatas];
                    this.defaultExpandedKeysMember = [];
                }
                
                this.treeData.forEach((item) => {
                    if (!departId) {
                        item.showChildren = false;
                        item.children = [];
                    }
                    this.$nextTick(() => {
                        let ref = item.id + "TreeLazy";
                        setTimeout(() => {
                            this.getNodeChecked(ref, item, ids);
                        }, 500);
                    });
                });
            });
        },
        getNodeChecked(ref, data, ids) {
            if (ids.indexOf(data.id) > -1) {
                if (this.$refs[ref] && this.$refs[ref][0]) {
                    this.$set(
                        this.$refs[ref][0].getNode(data.id).data,
                        "checked",
                        true
                    );
                }
            }
            if (data.children) {
                data.children.forEach((item) => {
                    this.getNodeChecked(ref, item, ids);
                });
            }
        },
        getAIIDeptAndMember(node, resolve) {
            if (node.level === 0) {
                this.memberList = this.handlerCheckedSelf(this.memberList);
                return resolve(this.memberList);
            } else {
                let children = node.data.children;
                this.$http("/api/manager/corpListDynamicLazy", {
                    query: {
                        departId: node.data.id,
                    },
                }).then((res) => {
                    if (res.data.code === "0") {
                        let datas = this.handlerCheckedSelf(res.data.data);
                        let arr = [...children, ...datas];
                        arr.forEach((element) => {
                            if (element.type === 0) {
                                element.leaf = true;
                            }
                        });
                        resolve(arr);
                        this.$forceUpdate();
                    }
                });
            }
        },
        handlerCheckedSelf(datas, ids, parentId) {
            datas.forEach((item) => {
                item.checked = false;
                item.showChildren = false;
                if (item.type === 1) {
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                if (ids.indexOf(item.id) > -1) {
                    let ref = item.id + "TreeLazy";
                    // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                } else {
                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                }
            });
            return datas;
        },
        //选择员工报单人
        onNodeClick(node, staffType, item, row) {
            if (node.dataType == "hasNext") {
                this.currentPage = node.currentPage;
                this.getData(node.parentId, {}, "", true);
            } else {
                let type = "";
                if (!staffType) {
                    type =
                        item.originData &&
                        item.originData.wechatBotDTO &&
                        item.originData.wechatBotDTO.bindType === 0 &&
                        item.originData.wechatBotDTO.wechatType === 0
                            ? "staff-t"
                            : "staff";
                } else {
                    type = staffType;
                }
                let tampNode = JSON.parse(JSON.stringify(node));
                if (type === "updown") {
                    let obj = JSON.parse(JSON.stringify(node));
                    let flag = obj.checked;
                    // 成员
                    // if (
                    //     node.wxOpenId &&
                    //     node.organizationId &&
                    //     staffType === "updown"
                    // ) {
                        this.$set(node, "checked", !flag);
                        this.$emit("onNodeClick", node, type, item, row);
                    // }
                } else {
                    if (node.type === 0 || node.type === 1) {
                        this.ergodicClient(type).then(() => {
                            this.$set(node, "checked", !node.checked);
                            if (node.checked) {
                               
                                tampNode.children = [];
                                tampNode.name = tampNode.label;
                                this.selectDepts.push(tampNode);
                                this.selectIds.push(tampNode.id);
                            } else {
                                this.selectDepts.forEach((item, index) => {
                                    if (node.id === item.id) {
                                        this.selectDepts.splice(index, 1);
                                    }
                                });
                                this.selectIds.forEach((item, index) => {
                                    if (node.id === item) {
                                        this.selectIds.splice(index, 1);
                                    }
                                });
                            }

                            if (type === "staff-t") {
                                WWOpenData.bind(this.$el);
                            }
                        });
                    }
                }
            }
            
            
        },
        setItems() {
            this.$emit("onNodeClick", this.selectDepts);
        },
        //搜索
        searchStaff() {
            let userId = localStorage.getItem("_uid");
            let url =
                "/portal-api/department/corpListLazy-v2?keyword=" +
                this.keyword +
                "&corpId=" +
                this.activeCorpId +
                "&userId=" +
                userId;
            this.FetchGet(url).then((res) => {
                this.treeData = [...res.list];
                this.treeData.forEach((item) => {
                    this.$nextTick(() => {
                        let ref = item.id + "TreeLazy";
                        setTimeout(() => {
                            this.getNodeChecked(ref, item, this.selectIds);
                        }, 500);
                    });
                });
            });
        },
        ergodicClient(type) {
            return new Promise((resolve) => {
                this.selectClient.forEach((client) => {
                    this.$set(client, "checked", false);
                });
                this.selectClient.forEach((client) => {
                    if (!type) {
                        if (!this.isMultiple) {
                            if (client.id === this.clientValue) {
                                this.$set(client, "checked", true);
                            }
                        } else {
                            this.clientValue.forEach((item) => {
                                if (client.id === item) {
                                    this.$set(client, "checked", true);
                                }
                            });
                        }
                    }
                });
                resolve(true);
            });
        },
        // 递归获取所有节点
        getAllNodeId(moduleDataList, type) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        if (type) {
                            this.selectIds.forEach((item) => {
                                if (moduleDataList[i].id == item) {
                                    // this.selectDepts.push(moduleDataList[i]);
                                    this.$set(
                                        moduleDataList[i],
                                        "name",
                                        moduleDataList[i].label || moduleDataList[i].name
                                    );
                                    this.$set(
                                        moduleDataList[i],
                                        "checked",
                                        true
                                    );
                                }
                            });
                        }
                        if (moduleDataList[i].children) {
                            this.getAllNodeId(moduleDataList[i].children, type);
                        }
                    }
                }
                resolve(true);
            });
        },
        //多选遍历树节点
        ergodicMemberTree(moduleDataList, node) {
            return new Promise((resolve) => {
                if (moduleDataList) {
                    for (let i = 0; i < moduleDataList.length; i++) {
                        this.$set(moduleDataList[i], "checked", false);
                        if (moduleDataList[i].children) {
                            this.ergodicMemberTree(
                                moduleDataList[i].children,
                                node
                            );
                        }
                    }
                }
                resolve(true);
            });
        },
        //在触发条件下遍历树节点
        ergodicMemberTree2(moduleDataList) {
            if (moduleDataList) {
                for (let i = 0; i < moduleDataList.length; i++) {
                    if (moduleDataList[i].checked) {
                        this.checkMemberList.push(moduleDataList[i]);
                    }
                    if (moduleDataList[i].children) {
                        this.ergodicMemberTree2(moduleDataList[i].children);
                    }
                }
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
    },
    watch: {
        keyword(val) {
            let tree = this.$refs.tree;
            if (tree) {
                tree.forEach((item) => {
                    item.filter(val);
                });
            }
        },
        memberInfo4Client: {
            handler(n) {},
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style  lang="less">
.staff-customer-input {
    height: 42px;
}

.member-contanier {
    // min-height: 300px;
    .customer-list {
        .customer-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            padding: 0 0 0 4px;
            cursor: pointer;
            .el-icon-check {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .customer-item:hover {
            background-color: #f6f8fd;
        }
    }
    .load-more-client {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 12px;
    }
    .custom-tree-node {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &.disabled:hover {
            cursor: not-allowed;
        }
        .custom-tree-node-left {
            display: flex;
            align-items: center;
            width: 90%;
            i {
                font-size: 14px;
                display: flex;
                align-items: center;
                padding-right: 6px;
                color: #c0c4cc;
            }
            .data-label {
               /* width: 100%;*/
                height: 28px;
                display: flex;
                align-items: center;
            }
        }

        .el-icon-check {
            font-size: 14px;
            font-weight: 600;
        }
    }
    .loadMore{
        color: #366aff;
        cursor: pointer;
    }      
    .el-tabs__nav-wrap::after {
        height: 0px !important;
    }
    ::v-deep.el-tree-node__content {
        &:hover {
            background-color: #f6f8fd;
        }
    }
    .el-tree-node:focus > .el-tree-node__content {
        background-color: white !important;
    }
    .el-tree-node__content {
        height: 30px !important;
    }
    .el-tree-node__content:hover {
        background-color: #f6f8fd !important;
    }
}
.staff-customer {
    .el-tree {
        .el-tree-node__expand-icon {
            display: none;
            font-size: 14px;
        }
        .el-icon-caret-right {
            &.down {
                transform: rotate(90deg);
            }
            &.right {
                transform: rotate(0);
            }
        }
        &.updown-tree {
            .el-tree-node__expand-icon {
                display: inline-block;
            }
        }
    }
}
</style>