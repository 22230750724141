<template>
    <div>
        <div class="qichat_drawer_content">
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber" style="margin-top: 28px">1</span>
                </div>
                <div class="des">
                    <div class="binding">
                        <p style="margin-right: 16px">
                          {{$t('botLink.weChatCustomerServiceDrawer.step1')}}
                        </p>
                        <el-button
                            type="primary"
                            size="mini"
                            @click="addChannel"
                            >+{{$t('botLink.weChatCustomerServiceDrawer.addChannel')}}</el-button
                        >
                    </div>
                    <div class="bindingCompany">
                        <div class="itemHeader">
                            <div class="companyItem">
                                <div class="companyItemName">{{$t('botLink.weChatCustomerServiceDrawer.name')}}</div>
                                <div class="companyappid">{{$t('botLink.weChatCustomerServiceDrawer.accessChannels')}}</div>
                                <div class="companyappid">
                                  {{$t('botLink.weChatCustomerServiceDrawer.account')}}
                                </div>
                                <div class="companyItemTime">{{$t('botLink.weChatCustomerServiceDrawer.bindTime')}}</div>
                                <div class="companyItemOperition">{{$t('botLink.operation')}}</div>
                                <div class="bindBot">{{$t('botLink.bindBot')}}</div>
                            </div>
                        </div>
                        <div class="itemContent">
                            <div
                                class="companyItem"
                                v-for="item in wxKfAppList"
                                :key="item.id"
                            >
                                <div class="companyItemName">
                                    {{ item.weChatKFBindInfo.name }}
                                </div>
                                <div class="companyappid">
                                    {{
                                        ["视频号", "微信内", "微信外"][
                                            Number(
                                                item.weChatKFBindInfo.channel
                                            )
                                        ]
                                    }}
                                </div>
                                <div class="companyappid">
                                    {{
                                        filterKFName(
                                            item.weChatKFBindInfo
                                                .wechatKFAccountOpenId
                                        )
                                    }}
                                </div>
                                <div class="companyItemTime">
                                    {{
                                        new Date(item.updateTime).Format(
                                            "yyyy-MM-dd hh:mm:ss"
                                        )
                                    }}
                                </div>
                                <div class="companyItemOperition">
                                    <el-link
                                        type="primary"
                                        @click="showWechatKFBindInfo(item)"
                                        class="view"
                                        >{{$t('botLink.view')}}</el-link
                                    >
                                    <!-- 解绑 -->
                                    <el-link
                                        @click="postWechatKFUnbind(item.id)"
                                        type="primary"
                                        class="unbind"
                                        >{{$t('botLink.unBind')}}</el-link
                                    >
                                    <!-- 复制链接 -->
                                    <p
                                        class="url"
                                        style="display: none"
                                        id="authUrlAddress"
                                    >
                                        {{ item.weChatKFBindInfo.url }}
                                    </p>
                                    <el-link
                                        type="primary"
                                        class="copy"
                                        id="copy"
                                        @click="
                                            copyDingdingUrl(
                                                'authUrlAddress',
                                                'copy',
                                                item.weChatKFBindInfo.url
                                            )
                                        "
                                        >{{$t('botLink.copyLink')}}</el-link
                                    >
                                    <div
                                        v-if="
                                            item.weChatKFBindInfo.channel == 2
                                        "
                                        class="download-scan"
                                    >
                                        <div class="image-box">
                                            <vue-qr
                                                logo-src=""
                                                :size="200"
                                                :margin="0"
                                                :auto-color="true"
                                                :dot-scale="1"
                                                :text="
                                                    item.weChatKFBindInfo.url
                                                "
                                            />
                                        </div>

                                        <span
                                            @click="
                                                miniAppCodeHandle(
                                                    'dwonload',
                                                    $event
                                                )
                                            "
                                            >{{$t('botLink.weChatCustomerServiceDrawer.download')}}</span
                                        >
                                    </div>

                                    <el-popover
                                        placement="right"
                                        width="200"
                                        trigger="click"
                                    >
                                        <div>
                                            <vue-qr
                                                logo-src=""
                                                :size="200"
                                                :margin="0"
                                                :auto-color="true"
                                                :dot-scale="1"
                                                :text="
                                                    item.weChatKFBindInfo.url
                                                "
                                            />
                                        </div>
                                        <el-link
                                            slot="reference"
                                            @click="
                                                miniAppCodeHandle(
                                                    item.weChatKFBindInfo.url,
                                                    'preview'
                                                )
                                            "
                                            v-if="
                                                item.weChatKFBindInfo.channel ==
                                                2
                                            "
                                            type="primary"
                                            class="view"
                                            >{{$t('botLink.weChatCustomerServiceDrawer.preview')}}</el-link
                                        >
                                    </el-popover>
                                </div>
                                <div class="bindBot">
                                    <span
                                        v-for="(cell, cellIndex) in item.bindBot
                                            ? item.bindBot.botInfos
                                            : []"
                                        :key="cell.botId"
                                        class="bindBotName"
                                    >
                                        <span v-if="cellIndex != 0">、</span
                                        >{{ cell.name }}</span
                                    >
                                    <span
                                        style="color: #366AFF"
                                        v-if="
                                            item.bindBot &&
                                            item.bindBot.count != 0
                                        "
                                        class="editBindBot el-icon-s-tools"
                                        @click="
                                            bindBotV2('wxServ', 'edit', item)
                                        "
                                    ></span>
                                    <el-button
                                        v-if="
                                            item.bindBot == null ||
                                            (item.bindBot &&
                                                item.bindBot.count == 0)
                                        "
                                        size="mini"
                                        type="primary"
                                        @click="
                                            bindBotV2('wxServ', 'add', item)
                                        "
                                        >{{$t('botLink.bindBot')}}</el-button
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            class="itemContent"
                            v-show="
                                wxKfAppList == null || wxKfAppList.length == 0
                            "
                        >
                            <br />
                            <p class="null" style="text-align: center">{{$t('botLink.emptyText')}}</p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber">2</span>
                </div>
                <div class="des">
                    <p>{{$t('botLink.weChatCustomerServiceDrawer.step2')}}</p>
                    <div>
                        <ul class="linkList">
                            <li v-for="(item, index) in linkList" :key="index">
                                <span class="step-title"
                                    >{{ item.step }}、{{ item.title }}</span
                                >
                                <div class="link-content">
                                    <div
                                        v-for="(cell, cellIndex) in item.link"
                                        :key="cellIndex"
                                        class="link-content-cell"
                                    >
                                        <span class="link-content-cell-text">{{
                                            cell.text
                                        }}</span>
                                        <span class="ink-content-cell-link">
                                            <a target="view_window" :href="cell.url">{{$t('botLink.weChatCustomerServiceDrawer.viewAccessGuide')}}</a>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="access-item">
                <div class="name">
                    <span class="stepNamber">3</span>
                </div>
                <div class="des">{{$t('botLink.accessCompleted')}}</div>
            </div>
        </div>
        <el-dialog
            id="add-channel"
            :title="diaTitle"
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="500px"
            :before-close="handleClose"
        >
            <div class="add-channel">
                <div class="add-channel-cell">
                    <div class="channel-cell-title">
                        <span class="channel-cell-title-pre">*</span>
                        <span>{{$t('botLink.weChatCustomerServiceDrawer.name')}}</span>
                    </div>
                    <div class="channel-cell-content">
                        <el-input
                            :disabled="isView"
                            size="mini"
                            v-model="activeChannel.name"
                            :placeholder="$t('common.inputPlaceholder')"
                        ></el-input>
                    </div>
                </div>
                <div class="add-channel-cell">
                    <div class="channel-cell-title">
                        <span>{{$t('botLink.weChatCustomerServiceDrawer.accessChannels')}}</span>
                    </div>
                    <div class="channel-cell-content">
                        <el-radio-group
                            :disabled="isView"
                            v-model="activeChannel.type"
                        >
                            <!-- <el-radio :label="0">视频号</el-radio> -->
                            <el-radio :label="1">{{$t('botLink.weChatCustomerServiceDrawer.withinWeChat')}}</el-radio>
                            <el-radio :label="2">{{$t('botLink.weChatCustomerServiceDrawer.withOutWeChat')}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="add-channel-cell">
                    <div class="channel-cell-title">
                        <span class="channel-cell-title-pre">*</span>
                        <span>{{$t('botLink.weChatCustomerServiceDrawer.accountPla')}}</span>
                    </div>
                    <div class="channel-cell-des">
                        {{$t('botLink.weChatCustomerServiceDrawer.accountTip')}}
                    </div>
                    <div class="channel-cell-content">
                        <el-select
                            :disabled="isView"
                            size="mini"
                            @change="changeServiceList"
                            v-model="activeChannel.service"
                            :placeholder="$t('botLink.weChatCustomerServiceDrawer.accountPla')"
                        >
                            <el-option
                                v-for="item in options"
                                :key="item.openKfId"
                                :label="item.name"
                                :value="item.openKfId"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-cascader
                            :disabled="isView"
                            size="mini"
                            placeholder="请选择客服账号"
                            v-model="activeChannel.service"
                            :options="options"
                            :props="{ multiple: true, emitPath: false }"
                            filterable
                        ></el-cascader> -->
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    v-if="isView"
                    size="mini"
                    @click="dialogVisible = false"
                    >{{$t('common.close')}}</el-button
                >
                <el-button
                    v-if="!isView"
                    size="mini"
                    @click="dialogVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    v-if="!isView"
                    size="mini"
                    type="primary"
                    @click="submitAddChannel"
                    >{{$t('common.confirm')}}</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import VueQr from "vue-qr";
export default {
    components: {
        VueQr,
    },
    data() {
        return {
            activeChannel: {
                name: "",
                type: 1,
                service: "",
            },
            wxKfAppList: [],
            diaTitle: "添加接入渠道",
            dialogVisible: false,
            isView: false,
            linkList: [
                {
                    step: 1,
                    title: "在微信内其他场景接入",
                    link: [
                        {
                            text: "在网页接入",
                            url: "https://work.weixin.qq.com/nl/act/p/3f8820e724cb44c5",
                        },
                        {
                            text: "在公众号菜单接入",
                            url: "https://work.weixin.qq.com/nl/act/p/5a46e8faa90c4a29",
                        },
                        {
                            text: "在小程序中接入",
                            url: "https://work.weixin.qq.com/nl/act/p/a733314375294bdd",
                        },
                        {
                            text: "在搜一搜品牌官方区接入",
                            url: "https://work.weixin.qq.com/nl/act/p/f0a520fb8804489d",
                        },
                        {
                            text: "在支付凭证中接入",
                            url: "https://work.weixin.qq.com/nl/act/p/ffe79b4865bf4dd8",
                        },
                    ],
                },
                {
                    step: 2,
                    title: "在微信外接入",
                    link: [
                        {
                            text: "在APP接入",
                            url: "https://work.weixin.qq.com/nl/act/p/a9ba0dc512144b62",
                        },
                        {
                            text: "在微信外的网页接入",
                            url: "https://work.weixin.qq.com/nl/act/p/4030a5b69149404d",
                        },
                    ],
                },
            ],
            options: [],
        };
    },
    computed: {},
    methods: {
        // 下载/预览码
        miniAppCodeHandle(msg, event) {
            let imgData =
                event.currentTarget.previousElementSibling.firstElementChild
                    .src;
            this.downloadFile("scan.png", imgData);
        },
        //下载
        downloadFile(fileName, content) {
            let aLink = document.createElement("a");
            let blob = this.base64ToBlob(content); //new Blob([content]);

            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = fileName;
            aLink.href = URL.createObjectURL(blob);

            // aLink.dispatchEvent(evt);
            //aLink.click()
            aLink.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            ); //兼容火狐
        },
        //base64转blob
        base64ToBlob(code) {
            let parts = code.split(";base64,");
            let contentType = parts[0].split(":")[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            let uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: contentType });
        },
        filterKFName(value) {
            console.log(value);
            let str = "";
            this.options.forEach((item) => {
                if (item.openKfId == value) {
                    str += item.name;
                }
            });
            return str;
        },
        changeServiceList(val) {
            console.log(val);
        },
        // 添加渠道
        addChannel() {
            this.isView = false;
            this.diaTitle = this.$t('botLink.weChatCustomerServiceDrawer.addChannel');
            this.dialogVisible = true;
            this.activeChannel = {
                name: "",
                type: 1,
                service: "",
            };
        },
        // 确认 添加渠道
        submitAddChannel() {
            if (this.activeChannel.name.trim() == "") {
                this.$message({
                    message: this.$t('botLink.weChatCustomerServiceDrawer.nameRequired'),
                    type: "warning",
                    duration: 2000,
                });
                return false;
            }
            if (this.activeChannel.service == 0) {
                this.$message({
                    message: this.$t('botLink.weChatCustomerServiceDrawer.accountRequired'),
                    type: "warning",
                    duration: 2000,
                });
                return false;
            }
            this.FetchPost(this.requestUrl.wechatKF.addBindWechatKF, {
                channel: this.activeChannel.type,
                name: this.activeChannel.name,
                wechatKFAccountOpenId: this.activeChannel.service,
            }).then((res) => {
                this.getWxKfAppList();
                this.dialogVisible = false;
                // if (res == "0") {
                //     // 添加成功
                //     this.getWxKfAppList();
                //     this.dialogVisible = false;
                // } else {
                //     this.$message.error(res.message);
                // }
            });
        },
        showWechatKFBindInfo(item) {
            this.isView = true;
            this.diaTitle = this.$t('botLink.weChatCustomerServiceDrawer.channelInfo')
            this.dialogVisible = true;
            this.activeChannel = {
                name: item.weChatKFBindInfo.name,
                type: Number(item.weChatKFBindInfo.channel),
                service: item.weChatKFBindInfo.wechatKFAccountOpenId,
            };
        },
        postWechatKFUnbind(id) {
            this.$confirm(this.$t('botLink.weChatCustomerServiceDrawer.unBindMsg'))
                .then((_) => {
                    this.FetchPut(
                        this.requestUrl.wechatKF.unbindBindWechatKF,
                        id
                    ).then((res) => {
                        if (res.code === "0") {
                            this.$message.success(this.$t('botLink.weChatCustomerServiceDrawer.unBindSuccess'));
                            this.getWxKfAppList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        copyDingdingUrl(urlType, copyclass, url) {
            var Url2 = document.getElementById(urlType).innerText;
            var copyclass = document.getElementById(copyclass);
            var oInput = document.createElement("input");
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success(this.$t('botLink.copySuccess'));
        },
        // 获取已添加渠道列表
        getWxKfAppList() {
            this.FetchGet(this.requestUrl.wechatKF.getWechatKFList).then(
                (res) => {
                    this.wxKfAppList = [...res];
                    // if (res == "0") {
                    //     this.wxKfAppList = [...res.data];
                    // } else {
                    //     this.$message.error(res.message);
                    // }
                }
            );
        },
        // 获取 获取客服账号
        getWechatCustomer() {
            this.FetchGet(this.requestUrl.wechatKF.getWechatCustomer).then(
                (res) => {
                    console.log(res, 434);
                    this.options = res;
                }
            );
        },
        bindBotV2(appType, handleType, item) {
            this.$emit("bindBotV2Children", { appType, handleType, item });
        },
        // 关闭添加/查看渠道弹窗
        handleClose() {
            this.dialogVisible = false;
        },
    },
    mounted() {
        this.getWxKfAppList();
        this.getWechatCustomer();
    },
};
</script>
<style lang="less" scoped>
.download-scan {
    .image-box {
        height: 0;
        width: 0;
        overflow: hidden;
    }
    span {
        color: #366AFF;
        cursor: pointer;
        display: inline-block;
        margin-right: 12px;
    }
}
.linkList {
    li {
        margin-top: 12px;
        .step-title {
            color: #606266;
        }
        .link-content {
            .link-content-cell {
                margin-top: 6px;
                line-height: 14px;
                height: 14px;
                .link-content-cell-text {
                    color: #909399;
                    font-size: 13px;
                }
                .ink-content-cell-link {
                    display: inline-block;
                    margin-left: 16px;
                    font-size: 13px;
                    cursor: pointer;
                    a {
                        color: #366AFF;
                    }
                }
            }
        }
    }
}
#add-channel {
    .add-channel {
        .add-channel-cell {
            margin-bottom: 12px;
            .channel-cell-title {
                margin-bottom: 4px;
                .channel-cell-title-pre {
                    color: red;
                }
            }
            .channel-cell-des {
                margin-bottom: 4px;
                color: #909399;
                font-size: 12px;
            }
            .channel-cell-content {
                .el-cascader {
                    width: 100%;
                }
                .el-select {
                    width: 100%;
                }
            }
        }
    }
}
</style>